import useIntersectionObserver from "@react-hook/intersection-observer";
import { debounce, isDate } from "lodash";
import { useLocalObservable } from "mobx-react-lite";
import type { DependencyList, EffectCallback, ReactNode } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ObservableSuspender } from "state-trees/src/Suspender";
import { assert } from "./utils";

export type EventHandler = (event: Event) => void;

export const useEventListener = (eventName: string, handler: EventHandler, element = window) => {
  // Create a ref that stores handler
  const savedHandler = React.useRef<EventHandler>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const eventListener = (event: Event) => assert(savedHandler.current)(event);
      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

export const useWhyDidYouUpdate = <P extends Record<string, any>>(name: string, props: P) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef<P>();

  useEffect(() => {
    const current = previousProps.current;
    if (current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...current, ...props });
      // Use this object to keep track of changed props
      const changesObj: Record<string, { from: any; to: any }> = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.warn("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

export const globalWhyDidYouUpdate = <P extends Record<string, any>>(name: string) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const ref: { current: P | null } = { current: null };

  const check = (props: P) => {
    const current = ref.current;
    if (current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...current, ...props });
      // Use this object to keep track of changed props
      const changesObj: Record<string, { from: any; to: any }> = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.warn(`[${name} why-did-you-update]`, name, changesObj);
      }
    }
    ref.current = props;
  };

  return check;
};

export const useSuspender = <T,>(fn: () => Promise<T>, deps: any[] = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => new ObservableSuspender(fn()), deps);
};

/** Used for tracking hover state on an element */
export function useHover<T extends HTMLElement = any>(): [
  boolean,
  { onMouseEnter: React.MouseEventHandler<T>; onMouseLeave: React.MouseEventHandler<T> }
] {
  const [value, setValue] = React.useState(false);

  const onMouseEnter = () => setValue(true);
  const onMouseLeave = () => setValue(false);

  return [value, { onMouseEnter, onMouseLeave }];
}

/** Used for tracking focus state on an element */
export function useFocus<T extends HTMLElement = any>(
  outerRef?: React.RefObject<T>
): [boolean, { onFocus: React.FocusEventHandler<T>; onBlur: React.FocusEventHandler<T>; ref: React.RefObject<T> }, boolean] {
  const [hasFocus, setFocus] = React.useState(false);
  const [hasFocusWithin, setFocusWithin] = React.useState(false);
  const innerRef = React.useRef<T>(null);
  const ref = outerRef ?? innerRef;

  const onFocus = (e: React.FocusEvent) => {
    const isRef = e.target === ref.current;
    setFocus(true);
    if (!isRef) {
      setFocusWithin(true);
    }
  };
  const onBlur = (e: React.FocusEvent) => {
    const isRef = e.target === ref.current;
    setFocus(false);
    if (!isRef) {
      setFocusWithin(false);
    }
  };

  return [hasFocus, { onFocus, onBlur, ref }, hasFocusWithin];
}

// from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, period: number) => {
  const savedCallback = useRef<() => void>();
  const id = useRef<ReturnType<typeof setInterval>>();
  const clearFunction = useCallback(() => {
    if (id.current) {
      clearInterval(id.current);
    }
  }, []);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (period !== null) {
      const intervalId = setInterval(tick, period);
      id.current = intervalId;
      return () => clearInterval(intervalId);
    }
  }, [period]);

  return clearFunction;
};

export function useDebouncedValue<T>(value: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const setValueDebounced = useCallback(
    debounce((v: T) => {
      setDebouncedValue(v);
    }, delay),
    []
  );
  useEffect(() => setValueDebounced(value), [value, delay]);
  return debouncedValue;
}

export const useDebounce = (callback: EffectCallback, deps: DependencyList, interval = 500) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounce(callback, interval), [interval, ...deps]);
};

/** Helper hook to generate Mobx local observable state, and (optionally) watch some props for changes */
export function useObservableState<S extends Record<string, any>>(singleton: S, propsToWatch?: Partial<S>) {
  const observable = useLocalObservable(() => singleton);

  useEffect(() => {
    if (propsToWatch) {
      Object.entries(propsToWatch).forEach(([key, value]: [keyof S, any]) => {
        observable[key] = value;
      });
    }
  }, [observable, propsToWatch]);

  return observable;
}

function getScrollInfo(element: HTMLElement | null) {
  if (!element) return { isMinScroll: true, isMaxScroll: false };
  const isMinScroll = element.scrollTop === 0;
  const isMaxScroll = Math.floor(element.scrollHeight) - Math.ceil(element.scrollTop) <= Math.ceil(element.clientHeight);
  return { isMinScroll, isMaxScroll };
}

/** Used to determine if an element is at the start or end of its scroll limits */
export function useScrollPosition(element: React.MutableRefObject<HTMLElement | null>) {
  const [position, setPosition] = React.useState<{ isMinScroll: boolean; isMaxScroll: boolean }>(getScrollInfo(element.current));

  useLayoutEffect(() => {
    setPosition(getScrollInfo(element.current));

    const handleScroll = () => {
      setPosition(getScrollInfo(element.current));
    };

    const target = element.current;
    if (target) target.addEventListener("scroll", handleScroll);

    return () => {
      if (target) target.removeEventListener("scroll", handleScroll);
    };
  }, [element]);

  useLayoutEffect(() => {
    setPosition(getScrollInfo(element.current));
  }, [element]);

  return position;
}

/**
 * Hook for detecting if a position: sticky element within a scroll container is currently sticking by putting a detector element that notices when it scrolls away
 */
export const useStickyDetector = (): [isSticking: boolean, element: ReactNode] => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { isIntersecting } = useIntersectionObserver(elementRef, {
    threshold: 1,
    initialIsIntersecting: true,
  });

  return [
    !isIntersecting,
    <div ref={elementRef} key="sticky-detector" data-testid="sticky-detector" style={{ position: "relative", top: 0, height: "0px" }} />,
  ];
};

/** Used to inject (and then cleanup) an inline script */
export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

const RECENCY_BUFFER = 10000; // 10 seconds
/** Used to check how recent a date is, with a customizable animation delay. */
export const useDateRecencyChecker = (
  date: number | Date,
  options?: {
    /** How recent should the date be? Defaults to 10s */
    recencyBuffer?: number;
    /** Whether to change the state after an animation delay */
    isAnimated?: boolean;
    /** How long the animation delay should be. Defaults to 500ms */
    animationDelay?: number;
  }
) => {
  // By default, we use an animation delay; if you need it to just update after the date falls out
  // of the buffer, you need to set `isAnimated` to false.
  const { recencyBuffer = RECENCY_BUFFER, isAnimated = true, animationDelay = 500 } = options ?? {};
  const timestamp = isDate(date) ? date.getTime() : date;
  const [isRecent, setIsRecent] = useState(timestamp > new Date().getTime() - recencyBuffer);

  useEffect(() => {
    const dateIsRecent = timestamp > new Date().getTime() - recencyBuffer;

    if (dateIsRecent) {
      setIsRecent(dateIsRecent);
    }

    // The state flips to false either after an animation delay, or after it falls out of the buffer
    const delay = isAnimated ? animationDelay : recencyBuffer;
    const timer = setTimeout(() => setIsRecent(false), delay);
    return () => clearTimeout(timer);
  }, [date, recencyBuffer, isAnimated, animationDelay]);

  return isRecent;
};

/** Scroll to the top of the window, once, on mount */
export const useScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
};
